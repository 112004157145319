export function handleSlideCount( embla_api, current ) {
	const handle_count = () => {
		current.innerHTML = embla_api.selectedScrollSnap() + 1;
	};

	embla_api
		.on( 'init', handle_count )
		.on( 'select', handle_count );

	return;
}

export function addTogglePrevNextBtnsActive( embla_api, prev_btn, next_btn ) {
	const toggle_prev_next_btns_state = () => {
		if ( embla_api.canScrollPrev() ) {
			prev_btn.removeAttribute( 'disabled' );
		} else {
			prev_btn.setAttribute( 'disabled', 'disabled' );
		}

		if ( embla_api.canScrollNext() ) {
			next_btn.removeAttribute( 'disabled' );
		} else {
			next_btn.setAttribute( 'disabled', 'disabled' );
		}
	};

	embla_api
		.on( 'select', toggle_prev_next_btns_state )
		.on( 'init', toggle_prev_next_btns_state )
		.on( 'reInit', toggle_prev_next_btns_state );

	return () => {
		prev_btn.removeAttribute( 'disabled' );
		next_btn.setAttribute( 'disabled', 'disabled' );
	};
}

export function addPrevNextBtnsClickHandlers( embla_api, prev_btn, next_btn ) {
	const scroll_prev = () => embla_api.scrollPrev();
	const scroll_next = () => embla_api.scrollNext();
	prev_btn.addEventListener( 'click', scroll_prev, false );
	next_btn.addEventListener( 'click', scroll_next, false );

	return () => {
		prev_btn.removeEventListener( 'click', scroll_prev, false );
		next_btn.removeEventListener( 'click', scroll_next, false );
	};
}
